<template>
  <Layout>

    <div class="align-items-center text-center page-headline mb-3">
      <div class="text-xl-left">
        <h1 class="display-3">{{ $t(`page.${role}.stats`) }}</h1>
      </div>
    </div>

    <div class="stats-diagrams-box">
      <div v-if="loading">
        <div class="text-center">
          <b-spinner variant="primary" />
        </div>
      </div>
      <div v-else>
        <b-alert v-if="error" variant="danger" class="mt-3" dismissible :show="Boolean(error)">{{ error }}</b-alert>
        <template v-else>

          <div class="card">
            <div class="card-body">

              <!-- Admin filters -->
              <div v-if="role === 'admin'" class="row mb-md-2 text-center table-filters">
                <div class="col-lg-6 col-md-auto text-md-left">
                  <div class="table-filters-range ml-0">
                    <label class="d-flex align-items-center">
                      <span class="text-nowrap mr-2">{{ $t('form.date-range') }}:</span>
                      <date-picker range v-model="range" :disabled-date="(date) => date >= new Date()"
                        :lang="datepickerConfig" value-type="YYYY-MM-DD" format="YYYY-MM-DD"
                        :placeholder="$t('form.date-range-placeholder')" :disabled="loading" input-class="form-control" />
                    </label>
                  </div>
                </div>
                <div class="col-lg-6 col-md-auto ml-auto text-md-right">
                  <div class="table-filters-label d-block d-md-inline-flex align-items-center flex-wrap">
                    <label class="d-inline-flex align-items-center">
                      <span class="text-nowrap mr-2">{{ $t('form.filter-by-company') }}:</span>
                      <AutocompleteSearchCompany v-model="company" />
                    </label>
                  </div>
                </div>
              </div>

              <!-- Company filters -->
              <div v-if="role === 'company'" class="row mb-md-2 text-center table-filters">
                <div class="col-lg-6 col-md-auto text-md-left">
                  <div class="table-filters-range ml-0">
                    <label class="d-flex align-items-center">
                      <span class="text-nowrap mr-2">{{ $t('form.date-range') }}:</span>
                      <date-picker range v-model="range" :disabled-date="(date) => date >= new Date()"
                        :lang="datepickerConfig" value-type="YYYY-MM-DD" format="YYYY-MM-DD"
                        :placeholder="$t('form.date-range-placeholder')" :disabled="loading" input-class="form-control" />
                    </label>
                  </div>
                </div>
                <div class="col-lg-6 col-md-auto ml-auto"></div>
              </div>

              <!-- Diagrams -->
              <div v-if="diagrams?.length">
                <div>
                  <h3>{{ $t('diagram.steps') }}</h3>
                  <apexchart height="380" type="line" class="apex-charts" :series="seriesSteps"
                    :options="chartOptionsSteps" />
                </div>

                <div>
                  <h3>{{ $t('diagram.calories-burned') }}</h3>
                  <apexchart height="380" type="line" class="apex-charts" :series="seriesCalories"
                    :options="chartOptionsCalories" />
                </div>

                <div>
                  <h3>{{ $t('diagram.pulse') }}</h3>
                  <apexchart height="380" type="line" class="apex-charts" :series="seriesPulse"
                    :options="chartOptionsPulse" />
                </div>
              </div>
              <div v-else class="text-center">
                <template v-if="range && range[0] && range[1]">{{ $t('table.no-data') }}</template>
              </div>

              <Debuginfo>
                <!-- <div>
                  <pre>company: {{ company }}</pre>
                </div> -->
                <div>
                  <pre>diagrams: {{ diagrams }}</pre>
                </div>
                <!-- <pre>seriesSteps: {{ seriesSteps }}</pre> -->
                <!-- <pre>chartOptionsSteps: {{ chartOptionsSteps }}</pre> -->
                <!-- <pre>seriesCalories: {{ seriesCalories }}</pre> -->
                <!-- <pre>chartOptionsSteps: {{ chartOptionsSteps }}</pre> -->
              </Debuginfo>

            </div>
          </div>
        </template>
      </div>
    </div>

  </Layout>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import Layout from "@/views/layouts/main";
import AutocompleteSearchCompany from "@/components/autocomplete-search-company";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    Layout,
    AutocompleteSearchCompany,
    DatePicker,
  },
  metaInfo() {
    return {
      title: this.$t('app.title') + " | " + this.$t(`page.${this.role}.stats`),
    };
  },
  data() {
    return {
      loading: false,
      error: "",

      company: null,

      // tableData: [],
      // totalRows: 0,
      // page: 1,
      // pageOptions: [25, 50, 100],
      // per_page: 25,

      range: [],
      datepickerConfig: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },

      diagrams: [],
      options: {
        chart: {
          stacked: false,
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [0, 2]
        },
        colors: ['#4fc6e1', '#ebf2f6', '#f1556c', '#4fc6e1'],
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter(y) {
              return y;
            }
          }
        },
        grid: {
          borderColor: '#f1f3fa'
        },
        legend: {
          offsetY: -10,
        },
        responsive: [{
          breakpoint: 600,
          options: {
            yaxis: {
              show: false
            },
            legend: {
              show: false
            }
          }
        }]
      },
    };
  },
  created() {
    if (this.$route.query?.range?.length && this.$route.query.range[0] && this.$route.query.range[1]) {
      this.range = [this.$route.query.range[0], this.$route.query.range[1]]
    } else {
      this.defaultRangeWeek()
    }

    this.loadData();
  },
  computed: {
    // ...mapState(useGlobalStore, ['userRoles']),
    ...mapState(useGlobalStore, {
      owner: 'user',
      userRoles: 'userRoles',
    }),
    role() {
      if (this.userRoles?.includes("a")) {
        return 'admin'
      }

      if (this.userRoles?.includes("c")) {
        return 'company'
      }

      return
    },
    chartOptionsSteps() {
      let options = {
        ...this.options,
        stroke: {
          width: [0, 0, 2]
        },
        colors: ['#01a2d9', '#ff6895'],
        xaxis: {
          categories: [],
        },
      }

      // upd via this.diagrams
      this.diagrams.map(d => {
        options.xaxis.categories.push(String(d.day))
      })

      return options
    },
    seriesSteps() {
      let series = [
        {
          name: this.$t('diagram.steps'),
          type: 'bar',
          data: []
        },
        {
          name: this.$t('diagram.steps-sum-value'),
          type: 'bar',
          data: []
        },
        {
          name: this.$t('diagram.steps-mid-value'),
          type: 'line',
          data: []
        }
      ]

      // upd via this.diagrams
      let mid = 0
      this.diagrams.map(d => {
        series[0].data.push(d.mid_steps)
        series[1].data.push(d.sum_steps)
        mid += d.mid_steps
      })

      mid = mid / this.diagrams.length
      this.diagrams.map(d => {
        series[2].data.push(Number(mid).toFixed())
      })

      return series
    },
    chartOptionsCalories() {
      let options = {
        ...this.options,
        stroke: {
          width: [0, 2]
        },
        colors: ['#f3be22', '#ff6895'],
        xaxis: {
          categories: [],
        },
      }

      // upd via this.diagrams
      this.diagrams.map(d => {
        options.xaxis.categories.push(String(d.day))
      })

      return options
    },
    seriesCalories() {
      let series = [
        {
          name: this.$t('diagram.calories-burned-sum-value'),
          type: 'bar',
          data: []
        },
        {
          name: this.$t('diagram.calories-burned-mid-value'),
          type: 'line',
          data: []
        }
      ]

      // upd via this.diagrams
      let mid = 0
      this.diagrams.map(d => {
        series[0].data.push(d.sum_calories)
        mid += d.sum_calories
      })

      mid = mid / this.diagrams.length
      this.diagrams.map(d => {
        series[1].data.push(Number(mid).toFixed())
      })

      return series
    },
    chartOptionsPulse() {
      let options = {
        ...this.options,
        stroke: {
          width: [0, 2]
        },
        colors: ['#ff6895', '#4fc6e1'], // min, middle val
        xaxis: {
          categories: [],
        },
      }

      // upd via this.diagrams
      this.diagrams.map(d => {
        options.xaxis.categories.push(String(d.day))
      })

      return options
    },
    seriesPulse() {
      let series = [
        {
          name: this.$t('diagram.pulse_mid'),
          type: 'bar',
          data: []
        },
        {
          name: this.$t('diagram.pulse-mid-value'),
          type: 'line',
          data: []
        }
      ]

      // upd via this.diagrams
      let mid = 0
      this.diagrams.map(d => {
        series[0].data.push(d.mid_pulse_mid)
        mid += d.mid_pulse_mid
      })

      mid = mid / this.diagrams.length
      this.diagrams.map(d => {
        series[1].data.push(Number(mid).toFixed())
      })

      return series
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['leadersDayList',]),
    async setQuery(newQuery, toHistory = false) {
      const query = Object.assign({}, { ...this.$route.query, ...newQuery });

      // delete empty fields
      Object.keys(query).forEach(key => {
        if (query[key] === undefined || query[key] === null || query[key] === '') {
          delete query[key];
        }
      })

      if (toHistory) {
        await this.$router.push({ query })
      } else {
        await this.$router.replace({ query }).catch(err => { }) // or t: new Date().getTime(), - timestamp
      }
    },
    async loadData() {
      try {
        this.loading = true;
        this.error = "";

        await this.loadRangeDiagram(false);
      } catch (error) {
        console.log("loadData Error: ", error);
        this.error = error
      } finally {
        this.loading = false;
      }
    },
    async loadRangeDiagram() {
      // console.log('loadRangeDiagram...')
      this.loading = true
      this.error = "";

      // "day_from": "2023-10-01", "day_to": "2023-10-27", "company_id": 25, "page": 0, "sort": "", "sortd": "a"
      const params = {
        // per_page: this.per_page,
        // page: this.page,
        sort: "day",
        sortd: "a",
        company_id: "",
        day_from: "",
        day_to: ""
      }

      if (this.range.length && this.range[0] && this.range[1]) {
        params.day_from = this.range[0]
        params.day_to = this.range[1]
      }

      if (this.role === 'admin') {
        params.company_id = Number(this.$route.query?.company_id) || ""
      }

      // console.log('params: ', params)
      const req = await this.leadersDayList(params);

      if (req?.data?.success) {
        this.diagrams = req.data?.list?.list || []
        this.company = req.data?.list?.companies[0] || null;
      } else {
        // console.log('loadClientData, req: ', req)
        this.error = this.$t('msg.error')

        if (req.message && req.response?.data?.error) {
          this.error = req.message + ': ' + this.$t('msg.' + req.response.data.error)
        }
      }

      this.loading = false;
    },
    defaultRangeWeek() {
      let date_today = new Date();

      let first_day_of_the_week = new Date(date_today.setDate(date_today.getDate() - date_today.getDay() + 1));
      let last_day_of_the_week = new Date(date_today.setDate(date_today.getDate() - date_today.getDay() + 7));
      // console.log(`The first date of the week is: ${this.$helpers.formatDate_YYYY_MM_DD(first_day_of_the_week)}`);
      // console.log(`The last day of the week is: ${this.$helpers.formatDate_YYYY_MM_DD(last_day_of_the_week)}`);

      // this.range = ["2023-09-25", "2023-10-01"]
      this.range = [this.$helpers.formatDate_YYYY_MM_DD(first_day_of_the_week), this.$helpers.formatDate_YYYY_MM_DD(last_day_of_the_week)]
    },
  },
  watch: {
    // watch query like nuxt watchQuery
    '$route.query'(q, old) {
      if (
        (this.role === 'admin' && (q.company_id || old.company_id) && (q.company_id !== old.company_id)) ||
        ((JSON.stringify(q.range) !== JSON.stringify(old.range))) // # compare arrays
      ) {
        this.loadRangeDiagram()
      }
    },
    'company.id'(company_id) {
      if (this.role === 'admin') {
        this.setQuery({ company_id }, true)
      }
    },
    range() {
      if (this.range.length && this.range[0] && this.range[1]) {
        this.setQuery({ range: this.range }, true)
      } else {
        this.setQuery({ range: "" }, true)
      }
    },
  },
};
</script>
<style scoped lang="scss">
.table-filters-range {
  max-width: 440px;
  margin: 0 auto;
}

.stats-diagrams {
  &-box {
    h3 {
      font-size: 20px;
    }
  }
}
</style>
